import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, defineMessages } from 'react-intl';
import Cookie from 'js-cookie';
import './menu.css';

import logo from '../assets/ewmp.png';

const pages = [
  { link: '/', key: 'who-we-are' },
  {
    link: '/ethos',
    key: 'ethos',
  },
  { link: '/founder', key: 'founder' },
];

const messages = defineMessages({
  page1: {
    id: 'menu.title.1',
    defaultMessage: 'WHO WE ARE',
  },
  page2: {
    id: 'menu.title.2',
    defaultMessage: 'ETHOS',
  },
  page3: {
    id: 'menu.title.3',
    defaultMessage: 'FOUNDER',
  },
});

class Menu extends Component {
  state = {
    currentPage: Cookie.get('page') || 0,
    menuOpen: false,
  };

  componentDidMount() {
    const currentPage = Cookie.get('page') || 0;

    this.setState({
      currentPage,
    });
  }

  setPage = (pageIndex) => {
    this.setCookiePage(pageIndex);
    this.setState({ currentPage: pageIndex, menuOpen: false });
  };

  setCookiePage = (pageIndex) => {
    Cookie.set('page', pageIndex);
  };

  handleClick = () => {
    const { currentPage } = this.state;

    Cookie.set('locale', this.props.intl.locale === 'en' ? 'zh' : 'en');
    this.setCookiePage(currentPage);
    window.location.reload();
  };

  toggleMenu = () => {
    let current = this.state.menuOpen;
    this.setState({ menuOpen: !current });
  };

  render() {
    const {
      intl: { formatMessage, locale },
    } = this.props;
    const { currentPage, menuOpen } = this.state;

    let burgerClass = menuOpen
      ? 'navbar-burger burger is-active'
      : 'navbar-burger burger';

    let navbarMenuClass = menuOpen ? 'navbar-menu is-active' : 'navbar-menu';

    return (
      <div className="hero">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link
                className="navbar-item logo"
                to="/"
                onClick={(e) => this.setPage(0)}
              >
                <img
                  src={logo}
                  width="270"
                  height="70"
                  alt="Edgewater Management Partners"
                />
              </Link>

              <div
                role="button"
                className={burgerClass}
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
                onClick={this.toggleMenu}
                style={{ height: 81 }}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </div>
            </div>
            <div className={navbarMenuClass}>
              <div className="navbar-end">
                {pages.map((page, index) => {
                  let classes =
                    parseInt(index) === parseInt(currentPage)
                      ? 'w-nav-link nav-link nav-link-current'
                      : 'w-nav-link nav-link';
                  return (
                    <Link
                      to={page.link}
                      key={page.key}
                      className={classes}
                      onClick={(e) => this.setPage(index)}
                    >
                      {formatMessage(messages['page' + (index + 1)])}
                    </Link>
                  );
                })}
                <div className="w-nav-link nav-link">
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      this.handleClick();
                    }}
                  >
                    {locale === 'en' ? '中文' : 'ENG'}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default injectIntl(Menu);
