import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import Cookie from 'js-cookie';

import Menu from './components/Menu';
import Footer from './components/Footer';
import Who from './pages/Who';
import Beliefs from './pages/Beliefs';
import Founders from './pages/Founders';
import translations from './i18n/locales';

const locale = Cookie.get('locale') || 'en';
const messages = translations[locale];

class App extends Component {
  render() {
    return (
      <IntlProvider locale={locale} key={locale} messages={messages}>
        <div className="main">
          <BrowserRouter>
            <React.Fragment>
              <Menu />
              <Route path="/" exact component={Who} />
              <Route path="/ethos" exact component={Beliefs} />
              <Route path="/founder" exact component={Founders} />
            </React.Fragment>
          </BrowserRouter>
          <Footer />
        </div>
      </IntlProvider>
    );
  }
}

export default App;
