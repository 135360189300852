import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Phone',
  },
  terms: {
    id: 'terms',
    defaultMessage: 'Terms & Privacy',
  },
  termsText: {
    id: 'termsText',
    defaultMessage:
      'We are committed to protecting and securing all information provided to us by our clients. For a copy of our full privacy policy statement, please contact us.',
  },
  copyright: {
    id: 'copyright',
    defaultMessage: 'Copyright',
  },
  copyrightText: {
    id: 'copyrightText',
    defaultMessage:
      '© 2019 Edgewater Management Partners, LLC All Rights Reserved',
  },
});

class Footer extends React.Component {
  render() {
    const {
      intl: { formatMessage },
    } = this.props;
    return (
      <footer className="footer">
        <div className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <h3 className="title is-4">
                  {formatMessage(messages.contact)}
                </h3>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>{formatMessage(messages.email)}:</p>
                      </td>
                      <td style={{"paddingLeft": 5}}>
                        <p>
                          <a href="mailto:info@edgewatermp.com">
                            info@edgewatermp.com
                          </a>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="column">
                <h3 className="title is-4">{formatMessage(messages.terms)}</h3>
                <p>{formatMessage(messages.termsText)}</p>
              </div>
              <div className="column">
                <h3 className="title is-4">
                  {formatMessage(messages.copyright)}
                </h3>
                <p>
                  © {new Date().getFullYear()}{' '}
                  {formatMessage(messages.copyrightText)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default injectIntl(Footer);
