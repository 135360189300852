import React from 'react';
import Layout from '../components/Layout';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';
import SimonImg from '../assets/simon.jpg';

const messages = defineMessages({
  founder2: {
    id: 'founders.1.name',
    defaultMessage: 'name',
  },
  founderDesc2: {
    id: 'founders.1.desc',
    defaultMessage: 'description',
  },
  founderDesc2Continued: {
    id: 'founders.1.desc2',
    defaultMessage: 'description',
  },
  founder1: {
    id: 'founders.2.name',
    defaultMessage: 'name',
  },
  founderDesc1: {
    id: 'founders.2.desc',
    defaultMessage: 'description',
  },
  founderDesc1Continued: {
    id: 'founders.2.desc2',
    defaultMessage: 'description',
  },
});

const founders = [
  { name: 'Simon Wu', image: SimonImg },
];

class Founders extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <Layout>
        <div className="columns page-section">
          {founders.map((founder, i) => {
            return (
              <React.Fragment key={i}>
                <div className="column">
                  <h1 className="title is-1 mainTitle">
                    <FormattedHTMLMessage {...messages['founder' + (i + 1)]} />
                  </h1>
                  <div className="content">
                    <FormattedHTMLMessage
                      {...messages[`founderDesc${i+1}`]}
                    />
                    <span>{currentYear - 2008}</span>
                    <FormattedHTMLMessage
                      {...messages[`founderDesc${i+1}Continued`]}
                    />
                  </div>
                  <img
                    className="founderImage"
                    src={founder.image}
                    alt={founder.name}
                  />
                </div>
              </React.Fragment>
            );
          })}
          <div className="column"></div>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(Founders);
