import React from 'react';
import Layout from '../components/Layout';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';

const messages = defineMessages({
  whoTitle: {
    id: 'who.title',
    defaultMessage: '<span class="mainColor">Who</span> we are',
  },
  whoDesc: {
    id: 'who.desc',
    defaultMessage:
      'Edgewater Management Partners, LLC ("Edgewater") is a New Jersey-based investment firm aimed at generating strong, risk-controlled, absolute returns for our partners. <br /> <br /> We have spent a combined 20 years analyzing, refining, and finally launching our proprietary investment process, culminating in a system that is: <br /> <br /> <ul> <li>All-weather - adaptable to both bull and bear markets</li> <li>Risk-controlled – multiple risk reduction mechanisms</li> <li> Asymmetric – systematic in identifying asymmetrical market opportunities </li> </ul>',
  },
  whoQuote: {
    id: 'who.quote',
    defaultMessage:
      '<blockquote className="colour-quote"> <p> Markets are constantly in a state of uncertainty and flux, and money is made by discounting the obvious and betting on the unexpected. </p> </blockquote> <p className="colour-author">- George Soros</p>',
  },
});
class Who extends React.Component {
  render() {
    return (
      <Layout>
        <div className="columns">
          <div className="column">
            <h1 className="title is-1 mainTitle">
              <FormattedHTMLMessage {...messages.whoTitle} />
            </h1>
          </div>
          <div className="column">
            <div className="content">
              <FormattedHTMLMessage {...messages.whoDesc} />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default injectIntl(Who);
