import React from 'react';
import Layout from '../components/Layout';
import { injectIntl, defineMessages, FormattedHTMLMessage } from 'react-intl';

const messages = defineMessages({
  belief0: {
    id: 'beliefs.0.title',
    defaultMessage: '<span class="mainColor">Who</span> we are',
  },
  beliefDesc0: {
    id: 'beliefs.0.desc',
    defaultMessage:
      'Edgewater Management Partners, LLC ("Edgewater") is a New Jersey-based investment firm aimed at generating strong, risk-controlled, absolute returns for our partners. <br /> <br /> We have spent a combined 20 years analyzing, refining, and finally launching our proprietary investment process, culminating in a system that is: <br /> <br /> <ul> <li>All-weather - adaptable to both bull and bear markets</li> <li>Risk-controlled – multiple risk reduction mechanisms</li> <li> Asymmetric – systematic in identifying asymmetrical market opportunities </li> </ul>',
  },
  beliefQuote0: {
    id: 'beliefs.0.quote',
    defaultMessage:
      '<blockquote class="colour-quote"> <p> Markets are constantly in a state of uncertainty and flux, and money is made by discounting the obvious and betting on the unexpected. </p> </blockquote> <p class="colour-author">- George Soros</p>',
  },
  belief1: {
    id: 'beliefs.1.title',
    defaultMessage: '<span class="mainColor">Who</span> we are',
  },
  beliefDesc1: {
    id: 'beliefs.1.desc',
    defaultMessage:
      'Edgewater Management Partners, LLC ("Edgewater") is a New Jersey-based investment firm aimed at generating strong, risk-controlled, absolute returns for our partners. <br /> <br /> We have spent a combined 20 years analyzing, refining, and finally launching our proprietary investment process, culminating in a system that is: <br /> <br /> <ul> <li>All-weather - adaptable to both bull and bear markets</li> <li>Risk-controlled – multiple risk reduction mechanisms</li> <li> Asymmetric – systematic in identifying asymmetrical market opportunities </li> </ul>',
  },
  beliefQuote1: {
    id: 'beliefs.1.quote',
    defaultMessage:
      '<blockquote class="colour-quote"> <p> Markets are constantly in a state of uncertainty and flux, and money is made by discounting the obvious and betting on the unexpected. </p> </blockquote> <p class="colour-author">- George Soros</p>',
  },
  belief2: {
    id: 'beliefs.2.title',
    defaultMessage:
      '<blockquote className="colour-quote"> <p> Markets are constantly in a state of uncertainty and flux, and money is made by discounting the obvious and betting on the unexpected. </p> </blockquote> <p className="colour-author">- George Soros</p>',
  },
  beliefDesc2: {
    id: 'beliefs.2.desc',
    defaultMessage: 'ccc',
  },
  beliefQuote2: {
    id: 'beliefs.2.quote',
    defaultMessage:
      '<blockquote class="colour-quote"> <p> Markets are constantly in a state of uncertainty and flux, and money is made by discounting the obvious and betting on the unexpected. </p> </blockquote> <p class="colour-author">- George Soros</p>',
  },
});

const beliefs = [0, 1, 2];

class Beliefs extends React.Component {
  render() {
    return (
      <Layout>
        {beliefs.map((belief, i) => {
          return (
            <React.Fragment key={belief}>
              <div className="columns page-section">
                <div className="column">
                  <h1 className="title is-1 mainTitle">
                    <FormattedHTMLMessage {...messages['belief' + i]} />
                  </h1>
                </div>
                <div className="column">
                  <div className="content">
                    <FormattedHTMLMessage {...messages['beliefDesc' + i]} />
                  </div>
                  <FormattedHTMLMessage {...messages['beliefQuote' + i]} />
                </div>
              </div>
              {i === (beliefs.length - 1) ? null : <div className="separator" />}
            </React.Fragment>
          );
        })}
      </Layout>
    );
  }
}

export default injectIntl(Beliefs);
